import styled from '@emotion/styled';
import { mobWidth } from 'constants/sizes';
import { ModernLayout } from 'UI';

import { FullLogo } from 'UI/Icons';
import { ButtonStyles } from 'UI/ModernButton/ModernButton';
import HolograktorLogo from '../HolograktorLogo/HolograktorLogo';

export const Wrapper = styled(ModernLayout)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  position: absolute;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(var(--unit) * 6);
  margin-top: calc(var(--unit) * 4);
  z-index: 20;

  @media (max-width: ${mobWidth}) {
    display: flex;
  }
`;

export const WayRayLogo = styled(FullLogo)`
  fill: var(--red);
  width: calc(var(--unit) * 20);
  justify-self: start;
  grid-column: 1;

  @media (max-width: ${mobWidth}) {
    margin-right: -15%;

    path:not([class^="icon"]) {
      display: none;
    }
  }
`;

export const HologractorLogo = styled(HolograktorLogo)`
  width: calc(var(--unit) * 20);
  justify-self: center;
  grid-column: 2;

  path {
    fill: var(--black);
  }

  @media (max-width: ${mobWidth}) {
    position: absolute;
    left: 58px;
    width: 8em;
    grid-column: 2;
    height: auto;

    > svg {
      width: 100%;
    }
  }
`;

export const Button = styled.a`
  ${ButtonStyles}
  justify-self: end;
  grid-column: 3;
  background: var(--mambo-green);
  color: var(--black);

  @media (max-width: ${mobWidth}) {
    font-weight: 600;
    margin-left: auto;
  }
`;
