import React, { FC } from 'react';

import { IMediaImage } from 'interfaces/media';
import { IWidget } from 'interfaces/widgets';

import Bottom from './Bottom/Bottom';
import SocialCard from './SocialCard/SocialCard';
import FollowCard from './FollowCard/FollowCard';
import {
  Container, Background,
} from './Footer.styled';

const Footer: FC<IWidget<IMediaImage, IMediaImage>> = ({
  header, media, text, slide,
}) => (
  <Container>
    <Background {...media?.[0]} />
    <SocialCard
      header={header}
      text={text}
      image={slide?.[0].media}
    />
    <FollowCard
      header={slide?.[0].header}
      text={slide?.[0].text}
      title={slide?.[0].title}
    />
    <Bottom
      legal={text.find((item) => item.title === 'legal')?.text}
    />
  </Container>
);

export default Footer;
