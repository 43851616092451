import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Parallax } from 'react-scroll-parallax';

import { IMediaImage } from 'interfaces/media';
import { IWidget } from 'interfaces/widgets';

import {
  TextWrapper, Picture, Description, MobilePicture, Title,
} from './Metaverse.styled';

const Metaverse: FC<IWidget<IMediaImage, IMediaImage>> = ({
  title, media, header,
}) => {
  const { isMobile } = useSelector((state: BlockState) => state);

  return (
    <>
      <Parallax y={[-20, 5]} disabled={isMobile}>
        <TextWrapper>
          <Title>{title}</Title>
          <Description>
            {header}
          </Description>
        </TextWrapper>
      </Parallax>

      <Picture {...media?.[0]} />
      <MobilePicture {...media?.[1]} />
    </>
  );
};

export default Metaverse;
