import styled from '@emotion/styled';
import { mobWidth } from 'constants/sizes';
import Markdown from 'markdown-to-jsx';

import { GatsbyPicture, GatsbyVideo, ModernLayout } from 'UI';
import AnimatedCar from './AnimatedCar/AnimatedCar';

export const Container = styled.div`
  position: relative;
  margin-bottom: 2em;

  @media (max-width: ${mobWidth}) {
    margin-bottom: 0;
  }
`;

export const Layout = styled(ModernLayout)`
  height: 100%;
  padding-top: calc(var(--unit) * 12);
  padding-bottom: calc(var(--unit) * 3);
  align-items: end;
  align-content: end;
  row-gap: 1.3rem;

  @media (max-width: ${mobWidth}) {
    align-content: space-between;
    align-items: start;
    align-content: start;
    row-gap: 1.5rem;
  }
`;

export const TextWrapper = styled(ModernLayout)`
  padding-top: calc(var(--unit) * 20);

  @media (max-width: ${mobWidth}) {
    display: none;
    padding-top: calc(var(--unit) * 2);
  }
`;

export const MobileText = styled(Markdown)`
  grid-column: 1 / -1;
  display: none;

  @media (max-width: ${mobWidth}) {
    display: block;
  }
`;

export const Title = styled.h1`
  grid-column: 1 / -1;
  color: var(--blue);

  @media (max-width: ${mobWidth}) {
    margin-top: 120vw;
    grid-column: 1 / -1;
    font-size: 17.5vw;
  }
`;

export const DescriptionWrapper = styled.div`
  grid-column: 1 / span 8;

  @media (max-width: ${mobWidth}) {
    grid-column: 1 / -1;
  }
`;

export const Text = styled(Markdown)`
  font-size: 1.4rem;

  @media (max-width: ${mobWidth}) {
    font-size: 1rem;
    color: var(--black);
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  /* position: sticky; */
  top: 0;
  z-index: 0;

  @media (max-width: ${mobWidth}) {
    height: 216.5vw;
  }
`;

export const Background = styled(GatsbyVideo)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  > video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

export const BackgroundImage = styled(GatsbyPicture)`
  grid-column: 1 / -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;

  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media (max-width: ${mobWidth}) {
    display: none;
    margin: 2rem -20px;
    height: auto;
    width: auto;
    position: static;

    > img {
      object-fit: cover;
      aspect-ratio: 1 / 1;
      height: 100vw;
    }
  }
`;

export const ImagesWrapper = styled(ModernLayout)`
  margin-top: calc(var(--unit) * 4);
  row-gap: calc(var(--unit) * 4);

  @media (max-width: ${mobWidth}) {
    margin-top: 0;
    row-gap: var(--unit);
    padding-left: 0;
    padding-right: 0;
  }
`;

export const Image = styled(GatsbyPicture)`
  grid-column: 1 / -1;

  &.index_1 {
    grid-column: 1 / span 10;
    grid-row: 2;
  }

  &.index_4 {
    grid-column: 11 / -1;
    grid-row: 2;

    > img {
      height: 100%;
      object-fit: cover;
    }
  }

  @media (max-width: ${mobWidth}) {
    &[class^="index"] {
      grid-column: 1 / -1;
      grid-row: auto;
    }
  }
`;

export const BackgroundMobile = styled(GatsbyPicture)`
  position: absolute;
  display: none;
  width: 100%;
  height: auto;
  left: 0;
  top: 0;
  height: auto;
  z-index: -1;

  > img {
    object-fit: cover;
  }

  @media (max-width: ${mobWidth}) {
    display: block;
  }
`;

export const AnimatedBack = styled(AnimatedCar)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 87%;
  z-index: -1;

  ::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), var(--white));
  }
`;

export const StickyContainer = styled.div`
  position: relative;
  height: 150vh;
  width: 100%;

  @media (max-width: ${mobWidth}) {
    height: auto;
  }
`;
