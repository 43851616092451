import React, { FC } from 'react';

import { IMediaImage } from 'interfaces/media';
import { IComponentWidgetSlide } from 'interfaces/widgets';

import {
  Wrapper, Slide, Background, Text,
} from './Shifter.styled';

interface IShifter {
  slides: IComponentWidgetSlide<IMediaImage>[];
  className?: string;
}

const Shifter: FC<IShifter> = ({ className, slides }) => (
  <Wrapper className={className}>
    {slides.map(({ header, id, media }) => (
      <Slide key={id}>
        <Text>{header}</Text>
        <Background {...media} />
      </Slide>
    ))}
  </Wrapper>
);

export default Shifter;
