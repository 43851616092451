import styled from '@emotion/styled';
import { mobWidth } from 'constants/sizes';

import { GatsbyPicture, ModernLayout } from 'UI';

export const Wrapper = styled.div`
  color: var(--white);
  margin-bottom: 3em;
`;

export const Slide = styled(ModernLayout)`
  height: 100vh;
  align-content: end;
  padding-top: calc(var(--unit) * 8);
  padding-bottom: calc(var(--unit) * 8);
  top: 0;
  bottom: 0;
  position: relative;
  background: var(--black);

  @media (max-width: ${mobWidth}) {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: calc(var(--unit) * 2);
    height: auto;
    row-gap: 20px;
  }
`;

export const Background = styled(GatsbyPicture)`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 0;

  > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  @media (max-width: ${mobWidth}) {
    position: static;
    grid-column: 1 / -1;
    width: 100%;
    height: auto;

    > img {
      aspect-ratio: 1 / 1;
      height: 100vw;
    }
  }
`;

export const Text = styled.p`
  grid-column: 1 / span 6;
  font-size: 1.4rem;
  position: relative;
  z-index: 1;

  @media (max-width: ${mobWidth}) {
    grid-column: 1 / -1;
    font-size: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
`;
