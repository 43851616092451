import React, { FC } from 'react';

import { IIcon } from './interfaces';

const RefreshIcon: FC<IIcon> = ({ className = '', ...props }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path
      d="M13.6498 2.35C12.9097 1.60485 12.0294 1.01356 11.0597 0.610231C10.09 0.206901 9.05001 -0.000494355 7.99977 8.84845e-07C3.57977 8.84845e-07 0.00976562 3.58 0.00976562 8C0.00976562 12.42 3.57977 16 7.99977 16C11.7298 16 14.8398 13.45 15.7298 10H13.6498C13.2378 11.1695 12.4731 12.1824 11.4611 12.8988C10.4491 13.6153 9.23971 14 7.99977 14C4.68977 14 1.99977 11.31 1.99977 8C1.99977 4.69 4.68977 2 7.99977 2C9.65977 2 11.1398 2.69 12.2198 3.78L8.99977 7H15.9998V8.84845e-07L13.6498 2.35Z"
    />
  </svg>
);

export default RefreshIcon;
