import styled from '@emotion/styled';
import { mobWidth, tabletWidth } from 'constants/sizes';

import {
  GatsbyPicture,
  ModernLayout,
} from 'UI';

export const Wrapper = styled(ModernLayout)`
  padding-top: 2em;
  padding-bottom: calc(var(--unit) * 28);
  row-gap: calc(var(--unit) * 4);
  grid-template-columns: repeat(8, minmax(0, 1fr));
  grid-template-areas:
    'r q q q q q q q'
    '. w w w w w w w'
    'e e e e e e e o'
    't y y y y y y y';

  font-size: 1.4em;

  @media (max-width: ${tabletWidth}) {
    padding-bottom: calc(var(--unit) * 14);
    grid-template-areas:
      'r r q q q q q q'
      '. . w w w w w w'
      'e e e e e e o o'
      't t y y y y y y';
  }

  @media (max-width: ${mobWidth}) {
    grid-template-areas:
      'r r r r'
      'q q q q'
      'w w w w'
      'o o o o'
      'e e e e'
      't t t t'
      'y y y y';

    row-gap: calc(var(--unit) * 2);
    padding-bottom: 20px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    font-size: 1em;
  }
`;

export const SlideOneText = styled.div`
  grid-area: r;

  @media (max-width: ${mobWidth}) {
    margin-left: calc(var(--unit) * 3);
    margin-right: calc(var(--unit) * 3);
  }
`;

const Picture = styled(GatsbyPicture)`
  @media (max-width: ${mobWidth}) {
    > img {
      aspect-ratio: 1 / 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vw;
      object-fit: cover;
    }
  }
`;

export const SlideOne = styled(Picture)`
  grid-area: q;
`;

export const SlideTwo = styled(Picture)`
  grid-area: w;
  margin-bottom: calc(var(--unit) * 14);

  @media (max-width: ${tabletWidth}) {
    margin-bottom: calc(var(--unit) * 10);
  }

  @media (max-width: ${mobWidth}) {
    margin-bottom: calc(var(--unit) * 10);
  }
`;

export const SlideThreeText = styled.div`
  grid-area: o;
  align-self: stretch;
  margin-bottom: calc(var(--unit) * 14);

  div {
    height: 100%;
  }

  @media (max-width: ${tabletWidth}) {
    margin-bottom: calc(var(--unit) * 10);
  }

  @media (max-width: ${mobWidth}) {
    margin-left: calc(var(--unit) * 3);
    margin-right: calc(var(--unit) * 3);
    margin-bottom: 0;
  }
`;

export const SlideThree = styled(Picture)`
`;

export const SlideThreeWrapper = styled.div`
  grid-area: e;
  margin-bottom: calc(var(--unit) * 14);

  @media (max-width: ${tabletWidth}) {
    margin-bottom: calc(var(--unit) * 8);
  }

  @media (max-width: ${mobWidth}) {
    margin-bottom: calc(var(--unit) * 10);
  }
`;

export const SlideFourWrapper = styled.div`
  grid-area: y;
  margin-bottom: -20%;

  @media (max-width: ${tabletWidth}) {
    margin-bottom: 0%;
  }

  @media (max-width: ${mobWidth}) {
    margin-bottom: 0;
  }
`;

export const SlideFourText = styled.div`
  grid-area: t;
  margin-bottom: -20%;
  align-self: stretch;

  div {
    height: 100%;
  }

  @media (max-width: ${mobWidth}) {
    margin-bottom: 0;
    margin-left: calc(var(--unit) * 3);
    margin-right: calc(var(--unit) * 3);
  }
`;

export const SlideFour = styled(Picture)``;

export const SlideFiveImage = styled(GatsbyPicture)`
  display: block;

  @media (max-width: ${mobWidth}) {
    img {
      aspect-ratio: 1 / 1;
      object-fit: cover;
      height: 100vw;
    }
  }
`;
