import styled from '@emotion/styled';
import { mobWidth } from 'constants/sizes';

import { GatsbyPicture, ModernLayout } from 'UI';

export const Wrapper = styled(ModernLayout)`
  top: 0;
  left: 0;
  height: 100vh;
  padding-top: calc(var(--unit) * 7);
  padding-bottom: calc(var(--unit) * 7);
  position: relative;
  color: var(--white);
  align-content: end;
  align-items: end;

  @media (max-width: ${mobWidth}) {
    align-content: space-between;
    padding-top: calc(var(--unit) * 3);
    padding-bottom: calc(var(--unit) * 3);
  }
`;

export const Background = styled(GatsbyPicture)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  @media (max-width: ${mobWidth}) {
    display: none;
  }
`;

export const BackgroundMobile = styled(Background)`
  display: none;

  @media (max-width: ${mobWidth}) {
    display: block;
  }
`;

export const Header = styled.h2`
  grid-column: 1 / span 6;

  @media (max-width: ${mobWidth}) {
    grid-column: 1 / -1;
  }
`;

export const Description = styled.p`
  font-size: 1.4rem;
  grid-column: 11 / -1;

  @media (max-width: ${mobWidth}) {
    font-size: 1rem;
    grid-column: 1 / -1;
  }
`;
