import React, { FC } from 'react';

import {
  Wrapper, WayRayLogo, Button, HologractorLogo,
} from './HolograktorMenu.styled';

interface IHologractorMenu {
  className?: string;
}

const HolograktorMenu: FC<IHologractorMenu> = ({ className }) => (
  <Wrapper className={className}>
    <WayRayLogo />
    <HologractorLogo />
    <Button href="https://wayray.com" target="_blank">wayray.com</Button>
  </Wrapper>
);

export default HolograktorMenu;
