import React, { FC, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Parallax } from 'react-scroll-parallax';

import { IMediaImage, IMediaSvg } from 'interfaces/media';
import { IWidget } from 'interfaces/widgets';

import {
  Wrapper, Container, WrapperText,
  Title, Header, Picture,
  Logo, TextDescription, TextHeader, TextFootnote,
} from './ARGlazing.styled';

const ARGlazing: FC<IWidget<IMediaImage, null>> = ({
  media, title, text, header,
}) => {
  const { isMobile } = useSelector((state: BlockState) => state);
  const wrapperRef = useRef<HTMLDivElement>();
  const getText = (name: string): string | null => (
    text?.find((item) => item.title === name)?.text
  );

  const image: IMediaImage = media.find((item) => item.mime.includes('image') && !item.mime.includes('svg'));
  const logo: IMediaSvg = media.find((item) => item.mime.includes('svg'));

  return (
    <Container>
      <Parallax y={[-20, 5]} disabled={isMobile}>
        <Wrapper ref={wrapperRef}>
          <Title>{title}</Title>
          {header && <Header>{header}</Header>}
        </Wrapper>
      </Parallax>

      {image && <Picture {...image} />}

      <WrapperText>
        {logo && <Logo media={logo} />}
        {getText('description') && <TextDescription>{getText('description')}</TextDescription>}
        {getText('header') && <TextHeader>{getText('header')}</TextHeader>}
        {getText('footnote') && <TextFootnote>{getText('footnote')}</TextFootnote>}
      </WrapperText>
    </Container>
  );
};

export default ARGlazing;
