import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { ModernLayout } from 'UI';
import HolograktorLogo from 'components/blocks/Holograktor/HolograktorLogo/HolograktorLogo';
import { mobWidth, tabletWidth } from 'constants/sizes';
import { FullLogo } from 'UI/Icons';

export const Wrapper = styled(ModernLayout)`
  grid-column: 1 / -1;

  row-gap: calc(var(--unit) * 12);
  padding: calc(var(--unit) * 8) 0;
  grid-template-areas:
    'a a a a a a a a b b b b b b b b'
    'c c c c c c . . . . . . d d d d';

  @media (max-width: ${tabletWidth}) {
    grid-template-areas:
      'a a a a a a a a a a a a a a a a'
      'b b b b b b b b b b b b b b b b'
      'c c c c c c c c d d d d d d d d';
  }

  @media (max-width: ${mobWidth}) {
    padding-left: 0%;
    padding-right: 0%;
    row-gap: calc(var(--unit) * 5);
    grid-template-areas:
      'a a a a'
      'b b b b'
      'c c c c'
      'd d d d';
  }
`;

const ButtonStyles = css`
  background: rgba(255, 255, 255, 0.16);
  height: calc(var(--unit) * 6);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  border-radius: var(--unit);
  font-weight: 600;

  :hover {
    color: var(--purple);
  }
`;

export const SocialLinksWrapper = styled.div`
  grid-area: b;
  display: flex;
  align-items: center;
  gap: calc(var(--unit) * 4);

  @media (max-width: ${tabletWidth}) {
    justify-content: space-between;
  }

  @media (max-width: ${mobWidth}) {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const Title = styled.p`
  font-weight: 600;
  color: var(--mambo-green);

  @media (max-width: ${mobWidth}) {
    grid-column: 1 / -1;
  }
`;

export const SocialLinks = styled.div`
  display: flex;
  gap: 1.5em;
  align-items: center;

  @media (max-width: ${mobWidth}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const IconWrapper = styled.span`
  width: calc(var(--unit) * 4);
  height: calc(var(--unit) * 4);
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white);
  border-radius: calc(var(--unit) / 2);

  svg {
    fill: var(--black);
    width: calc(var(--unit) * 2);
    height: calc(var(--unit) * 2);
  }
`;

export const SocialLink = styled.a`
  display: flex;
  gap: 0.67em;
  font-weight: bold;
  align-items: center;

  :hover {
    color: var(--purple);

    ${IconWrapper} {
      background: var(--purple);

      svg {
        fill: var(--white);
      }
    }
  }
`;

export const LogosWrapper = styled.div`
  grid-area: a;
  align-self: end;
  display: flex;
  gap: 1em;

  svg {
    fill: white;
    height: calc(var(--unit) * 4);
  }

  @media (max-width: ${tabletWidth}) {
    justify-content: space-between;
  }

  @media (max-width: ${mobWidth}) {
    align-items: center;
  }
`;

export const WayRayLogo = styled(FullLogo)`
  @media (max-width: ${mobWidth}) {
    margin-right: -7em;

    path:not([class^="icon"]) {
      display: none;
    }
  }
`;

export const Logo = styled(HolograktorLogo)`
  max-height: calc(var(--unit) * 2);
  margin-top: 0.7em;

  @media (max-width: ${mobWidth}) {
    margin-top: 0.4em;
  }
`;

export const SiteLink = styled.a`
  ${ButtonStyles}
  padding: 0 1.6em;

  @media (max-width: ${mobWidth}) {
    margin-left: auto;
  }
`;

export const LegalInfo = styled.p`
  grid-area: c;
  color: var(--gray);
  white-space: pre-wrap;
`;

export const LegalLinksWrapper = styled.div`
  grid-area: d;
  display: flex;
  gap: 2em;
  justify-self: end;

  @media (max-width: ${mobWidth}) {
    gap: 0;
    justify-self: stretch;
    justify-content: space-between;
  }
`;

export const LegalLink = styled.a`
  color: var(--gray);

  :hover {
    color: var(--purple);
  }
`;
