import React, { FC } from 'react';

import { IMediaImage } from 'interfaces/media';
import { IWidget } from 'interfaces/widgets';

import {
  Wrapper, Background, BackgroundMobile, Header, Description,
} from './Disinfection.styled';

const Disinfection: FC<IWidget<null, IMediaImage>> = ({
  media, header, text,
}) => {
  const desktop = media[0];
  const mobile = media[1];
  const description = text.find((item) => item.title === 'description')?.text;

  return (
    <Wrapper>
      <Background {...desktop} />
      <BackgroundMobile {...mobile} />
      <Header>{header}</Header>
      <Description>{description}</Description>
    </Wrapper>
  );
};

export default Disinfection;
