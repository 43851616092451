import styled from '@emotion/styled';
import { mobWidth, tabletWidth } from 'constants/sizes';

import { ModernLayout } from 'UI';
import { ButtonStyles } from 'UI/ModernButton/ModernButton';

export const Wrapper = styled(ModernLayout)`
  grid-column: 1 / -1;

  background: rgba(255, 255, 255, 0.08);
  border-radius: var(--unit);
  padding-top: calc(var(--unit) * 8);
  padding-bottom: calc(var(--unit) * 8);
  backdrop-filter: blur(calc(var(--unit) * 12));

  row-gap: calc(var(--unit) * 12);

  @media (max-width: ${tabletWidth}) {
    row-gap: calc(var(--unit) * 6);
  }

  @media (max-width: ${mobWidth}) {
    row-gap: calc(var(--unit) * 2);
  }
`;

export const Title = styled.h2`
  color: var(--white);
  grid-column: 1 / -1;
  grid-row: 1;
`;

export const Header = styled.p`
  font-size: 1.4em;
  grid-column: 1 / span 6;

  @media (max-width: ${tabletWidth}) {
    grid-column: 1 / 8;
  }

  @media (max-width: ${mobWidth}) {
    font-size: 1em;
    grid-column: 1 / -1;
    grid-row: 2;
    margin-bottom: calc(var(--unit) * 5);
  }
`;

const SmallWrapper = styled.div`
  display: grid;
  gap: calc(var(--unit) * 2);
`;

export const PressKitWrapper = styled(SmallWrapper)`
  grid-row: 2;
  grid-column: 8 / span 4;
  grid-template-columns: 1fr 1fr;

  @media (max-width: ${tabletWidth}) {
    grid-column: 9 / -1;
  }

  @media (max-width: ${mobWidth}) {
    grid-column: 1 / -1;
    grid-row: 3;
    margin-bottom: calc(var(--unit) * 5);
  }
`;

export const SpanTitle = styled.p`
  color: var(--mambo-green);
  grid-column: 1 / -1;
  font-weight: 600;
`;

export const ContactWrapper = styled(SmallWrapper)`
  grid-column: 12 / -1;
  grid-template-columns: 2fr 2fr 1fr;

  @media (max-width: ${tabletWidth}) {
    grid-column: 9 / -1;
  }

  @media (max-width: ${mobWidth}) {
    grid-column: 1 / -1;
    grid-auto-flow: row;
    grid-template-columns: 1fr;
  }
`;

export const ButtonLink = styled.a`
  ${ButtonStyles}

  white-space: nowrap;
  font-weight: 600;

  :hover {
    color: var(--purple);
  }
`;

export const PressKitLink = styled(ButtonLink)`
  background: var(--white);
  color: var(--black);

  :hover {
    background: var(--purple);
    color: var(--white);
  }

  @media (max-width: ${mobWidth}) {
    grid-column: 1 / -1;
  }
`;
