import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Parallax } from 'react-scroll-parallax';

import { IMediaImage } from 'interfaces/media';
import { IWidget } from 'interfaces/widgets';

import {
  Container, Wrapper, Title,
  SlideOneText, SlideOneImage,
  SecondWrapper, SlideTwoText, SlideTwoImage,
  SlideThree, SlideThreeText, SlideThreeImage,
  SlideFourImage, SlideFourWrapper,
} from './Interior.styled';

const Interior: FC<IWidget<null, IMediaImage>> = ({
  header, slide,
}) => {
  const { isMobile, isTablet } = useSelector((state: BlockState) => state);

  const one = slide[0];
  const two = slide[1];
  const three = slide[2];
  const four = slide[3];

  return (
    <Container>
      <Wrapper>
        <Title>
          <Parallax y={[-30, 30]} disabled={isMobile}>
            {header}
          </Parallax>
        </Title>
        <SlideOneText>
          <Parallax y={[-10, 10]} disabled={isMobile}>
            {one.header}
          </Parallax>
        </SlideOneText>
      </Wrapper>
      <SlideOneImage {...one.media} />
      <SecondWrapper>
        <SlideTwoText>{two.header}</SlideTwoText>
        <SlideTwoImage {...two.media} />
        <SlideThreeText>
          <Parallax y={[20, -20]} disabled={isMobile || isTablet}>
            {three.header}
          </Parallax>
        </SlideThreeText>
        <SlideThree>
          <Parallax y={[20, -20]} disabled={isMobile || isTablet}>
            <SlideThreeImage {...three.media} />
          </Parallax>
        </SlideThree>
        <SlideFourWrapper>
          <Parallax y={[10, -50]} disabled={isMobile || isTablet}>
            <SlideFourImage {...four.media} />
          </Parallax>
        </SlideFourWrapper>
      </SecondWrapper>
    </Container>
  );
};

export default Interior;
