import styled from '@emotion/styled';
import { mobWidth } from 'constants/sizes';

import { GatsbyPicture, ModernLayout } from 'UI';

export const Title = styled.h2`
  grid-column: 1 / span 8;

  @media (max-width: ${mobWidth}) {
    grid-column: 1 / -1;
  }
`;

export const TextWrapper = styled(ModernLayout)`
  /* background:var(--black); */
  /* color: var(--white); */
  padding-top: calc(var(--unit) * 10);
  padding-bottom: calc(var(--unit) * 4);
  row-gap: calc(var(--unit) * 2);

  @media (max-width: ${mobWidth}) {
    padding-top: 2em;
    box-shadow: 0 0 0 2px #000;
  }
`;

export const Description = styled.p`
  font-size: 1.4rem;
  grid-column: 1 / span 8;

  @media (max-width: ${mobWidth}) {
    font-size: 1rem;
    grid-column: 1 / -1;
  }
`;

export const Picture = styled(GatsbyPicture)`
  @media (max-width: ${mobWidth}) {
    display: none;
  }
`;

export const MobilePicture = styled(GatsbyPicture)`
  display: none;

  @media (max-width: ${mobWidth}) {
    display: block;
  }
`;
