import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Parallax } from 'react-scroll-parallax';

import { IMediaImage } from 'interfaces/media';
import { IWidget } from 'interfaces/widgets';

import {
  Wrapper,
  SlideOneText, SlideOne,
  SlideTwo,
  SlideThreeText, SlideThree, SlideThreeWrapper,
  SlideFour, SlideFourText, SlideFourWrapper,
  SlideFiveImage,
} from './VRRC.styled';

const VRRC: FC<IWidget<null, IMediaImage>> = ({
  slide,
}) => {
  const { isMobile, isTablet } = useSelector((state: BlockState) => state);

  const one = slide.find((item) => item.title === 'one');
  const two = slide.find((item) => item.title === 'two');
  const three = slide.find((item) => item.title === 'three');
  const four = slide.find((item) => item.title === 'four');
  const six = slide.find((item) => item.title === 'six');

  return (
    <>
      <Wrapper>
        {one?.media && (
          <>
            <SlideOneText>{one.header}</SlideOneText>
            <SlideOne {...one.media} />
          </>
        )}
        {two?.media && <SlideTwo {...two.media} />}
        {three?.media && (
          <>
            <SlideThreeText>
              <Parallax y={[20, -20]} disabled={isTablet || isMobile}>
                {three.header}
              </Parallax>
            </SlideThreeText>
            <SlideThreeWrapper>
              <Parallax y={[20, -20]} disabled={isTablet || isMobile}>
                <SlideThree {...three.media} />
              </Parallax>
            </SlideThreeWrapper>
          </>
        )}
        {four?.media && (
          <>
            <SlideFourText>
              <Parallax y={[20, -60]} disabled={isTablet || isMobile}>
                {four.header}
              </Parallax>
            </SlideFourText>
            <SlideFourWrapper>
              <Parallax y={[20, -60]} disabled={isTablet || isMobile}>
                <SlideFour {...four.media} />
              </Parallax>
            </SlideFourWrapper>
          </>
        )}
      </Wrapper>
      {six?.media && <SlideFiveImage {...six.media} />}
    </>
  );
};

export default VRRC;
