import styled from '@emotion/styled';

import { mobWidth } from 'constants/sizes';
import { GatsbyPicture, GatsbyVideo, ModernLayout } from 'UI';

export const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: row;

  position: relative;
  z-index: 1;
`;

export const BackgroundWrapper = styled.div`
  position: relative;
  width: 100%;
  top: 0;
  left: 0;

  @media (max-width: ${mobWidth}) {
    display: none;
  }
`;

export const MobileBackgroundWrapper = styled.div`
  display: none;
  width: 100%;
  aspect-ratio: 1 / 1;
  height: 100vw;

  @media (max-width: ${mobWidth}) {
    display: block;
  }
`;

export const BackgroundVideo = styled(GatsbyVideo)`
  @media (max-width: ${mobWidth}) {
    video {
      aspect-ratio: 1 / 1;
      object-fit: cover;
      height: 100vw;
    }
  }
`;

export const SecondSlide = styled(ModernLayout)`
  background: var(--white);
  padding-top: 6em;
  padding-bottom: calc(var(--unit) * 3);
  align-content: start;
  align-items: start;
  grid-template-rows: auto 1fr;
`;

export const FirstSlideContainer = styled.div`
  display: grid;
  align-content: end;
  margin-bottom: -3%;
  padding-top: 6em;
  z-index: 1;
  background: var(--white);

  @media (max-width: ${mobWidth}) {
    height: auto;
    margin-bottom: 0;
    padding-top: 0;
  }
`;

export const FirstSlide = styled(ModernLayout)`
  padding-bottom: calc(var(--unit) * 3);
  position: relative;
  align-content: start;
  align-items: start;
  grid-template-rows: auto 1fr;

  @media (max-width: ${mobWidth}) {
    margin-bottom: 0;
    padding-top: 6em;
  }
`;

export const Header = styled.h2`
  grid-column: 1 / span 8;
  margin-bottom: calc(var(--unit) * 12);

  @media (max-width: ${mobWidth}) {
    grid-column: 1 / -1;
    margin-bottom: 0.5em;
  }
`;

export const HeaderOne = styled(Header)`
  margin-bottom: calc(var(--unit) * 12);
`;

export const Title = styled.p`
  grid-column: 1 / span 8;
  grid-row: 1;
  font-weight: 600;

  @media (max-width: ${mobWidth}) {
    grid-column: 1 / -1;
    margin-bottom: 0.2em;
  }
`;

export const Description = styled.p`
  grid-column: 11/-1;
  font-size: 1.4em;
  grid-row: 3;

  @media (max-width: ${mobWidth}) {
    grid-column: 1 / -1;
    font-size: 1em;
  }
`;

export const DescriptionOne = styled(Description)`
  grid-row: 3;
  margin-bottom: 2em;
`;

export const PhonesWrapper = styled.div`
  background: var(--white);
  display: grid;
  justify-content: center;
  align-items: start;
  align-content: center;
  grid-auto-flow: column;
  gap: 3em;

  @media (max-width: ${mobWidth}) {
    padding-left: calc(var(--unit) * 3);
    padding-right: calc(var(--unit) * 3);
    gap: 1em;
  }
`;

export const PhoneImage = styled(GatsbyPicture)`
  height: auto;
  width: 26.5em;
  display: block;
  margin: 20em 0 0em;

  @media (max-width: ${mobWidth}) {
    margin: 10em 0 4em;
    width: 100%;
  }
`;

export const OnePhoneImage = styled(PhoneImage)`
  top: 5.6em;
  margin: 5.6em 0;

  @media (max-width: ${mobWidth}) {
    position: static;
    margin: 4em 0;
    width: 100%;
  }
`;
