/* eslint-disable max-lines */
import styled from '@emotion/styled';
import { mobWidth } from 'constants/sizes';

import {
  GatsbyPicture,
  ModernLayout,
} from 'UI';

export const Wrapper = styled(ModernLayout)`
  padding-top: calc(var(--unit) * 13);
  padding-bottom: calc(var(--unit) * 13);
  row-gap: calc(var(--unit) * 4);

  @media (max-width: ${mobWidth}) {
    padding-top: calc(var(--unit) * 10);
    padding-bottom: calc(var(--unit) * 10);
  }
`;

export const Description = styled.p`
  font-size: 1.4em;
  grid-column: 1 / span 8;
  grid-row: 1;

  @media (max-width: ${mobWidth}) {
    grid-column: 1 / -1;
    font-size: 1em;
  }
`;

export const SonsorsWrapper = styled(ModernLayout)`
  height: 100vh;
  position: relative;
  align-content: start;
  color: var(--white);
  padding-top: calc(var(--unit) * 8);
  padding-bottom: calc(var(--unit) * 8);
  row-gap: calc(var(--unit) * 3.75);
  margin-bottom: 5em;

  @media (max-width: ${mobWidth}) {
    padding-top: 0;
    color: var(--black);
    padding-left: 0;
    padding-right: 0;
    height: auto;
    padding-bottom: calc(var(--unit) * 4);
    margin-bottom: 0;
    background: var(--black);
    color: var(--white);
  }
`;

export const Background = styled(GatsbyPicture)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  @media (max-width: ${mobWidth}) {
    position: static;
    grid-column: 1 / -1;
    grid-row: 1;
    z-index: 0;

    img {
      aspect-ratio: 1 / 1;
      object-fit: cover;
      height: 100vw;
    }
  }
`;

export const Slide = styled.p`
  font-size: 1.4rem;
  grid-column: 11 / -1;

  @media (max-width: ${mobWidth}) {
    padding: 0 20px;
    grid-column: 1 / -1;
    font-size: 1em;
  }
`;
