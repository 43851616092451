import React, { forwardRef } from 'react';

import { IMediaImage } from 'interfaces/media';
import { IComponentWidgetSlide } from 'interfaces/widgets';

import {
  Wrapper, Background, Title, Description, BackgroundMobile,
} from './Slyde.styled';

interface ISlide extends IComponentWidgetSlide<IMediaImage> {
  index: number;
}

const Slide = forwardRef<HTMLDivElement, ISlide>(({
  media,
  title,
  header,
  index,
  file,
}, ref) => (
  <Wrapper
    ref={ref}
    className={index === 0 ? 'first active' : null}
  >
    <Title>{title}</Title>
    <Background {...media} />
    <BackgroundMobile src={file?.localFile.publicURL} />
    <Description>{header}</Description>
  </Wrapper>
));

export default Slide;
