/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  FC, useEffect, useRef, useState,
} from 'react';
import html2canvas from 'html2canvas';

import { IMediaImage } from 'interfaces/media';
import { IComponentWidgetText } from 'interfaces/widgets';
import RefreshIcon from 'UI/Icons/RefreshIcon';
import {
  FacebookIcon, LinkedinIcon, TwitterIcon, CheckIcon, LinkIcon,
} from 'UI/Icons';

import {
  Wrapper, Icon, Header, RandomTitle, RandomText,
  SocialWrapper, SocialShare, SocialIcon, CopyLink, ShareTitle, SocialShareMobile,
  ShareWrapper, ShareMobileTitle,
} from './SocialCard.styled';

interface ISocialCard {
  header: string;
  text: IComponentWidgetText[];
  image: IMediaImage;
}

const SocialCard: FC<ISocialCard> = ({
  header, text, image,
}) => {
  const [randomNumber, setRandomNumber] = useState<number>(0);
  const [copied, setCopied] = useState<boolean>(false);
  const [canShare, setCanShare] = useState<boolean>(true);
  const shareRef = useRef<HTMLDivElement>();

  const variants = text?.find(({ title }) => title !== 'hashtags');
  const variantsTexts = variants?.text?.split(/\r?\n/) || [];

  const setRandom = () => {
    const count = variantsTexts.length;
    const rand = Math.round(Math.random() * count);
    setRandomNumber(rand);
  };

  const copyText = (string: string) => navigator?.clipboard?.writeText(string);

  const setCopy = async () => {
    try {
      await copyText(`https://holograktor.com/share/${randomNumber}`);
    } catch (error) {
      console.warn(error);
    }

    setCopied(true);
  };

  const getRandomURL = () => encodeURIComponent(`https://holograktor.com/share/${randomNumber}`);

  const nativeShare = async () => {
    const { mime } = image;
    const { images } = image.localFile.childImageSharp.gatsbyImageData;
    const { src } = images.fallback;

    /* html2canvas(shareRef.current, {
      scrollX: 0,
      scrollY: -window.scrollY,
      width: 1200,
      height: 628,
      windowWidth: 1200,
      windowHeight: 628,
      onclone: (clonedDoc) => {
        // eslint-disable-next-line no-param-reassign
        clonedDoc.getElementById('shareElement').style.display = 'block';
      },
    }).then((canvas) => {
      canvas.toBlob(async (blob) => {
        const sharedData = {
          text: `${header} ${variantsTexts[randomNumber]} https://holograktor.com/`,
          // title: `${header} ${variantsTexts[randomNumber]} https://holograktor.com/`,
          files: [
            new File([blob], 'holograktor.jpeg', {
              type: blob.type,
            }),
          ],
        };

        const { navigator } = window;
      });
      console.log(canvas);
    }); */

    const url = `https://holograktor.com/share/${randomNumber}`;

    const sharedData = {
      text: `${header} ${variantsTexts[randomNumber]}`,
      url,
    };

    try {
      await navigator.share(sharedData);
    } catch (error) {
      try {
        await copyText(`${header} ${variantsTexts[randomNumber]} ${url}`);
        setCopied(true);
      } catch (errorCopy) {
        console.warn(errorCopy);
      }
      console.warn(error);
    }
  };

  const openShareWindow = (url: string) => {
    window.open(url, '', '_blank, width=640, height=500, resizable=yes, scrollbars=yes');
    return false;
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const hash = Number(window.location.hash?.replace('#', ''));
      if (variantsTexts[hash] && hash !== 0) {
        setRandomNumber(hash);
      } else {
        setRandom();
      }
    }
    setCanShare(true);
  }, []);

  return (
    <Wrapper>
      <Header>{header}</Header>
      <RandomText>{variantsTexts[randomNumber]}</RandomText>
      <RandomTitle onClick={setRandom}>
        <Icon>
          <RefreshIcon />
        </Icon>
        {variants.title}
      </RandomTitle>

      <ShareWrapper
        ref={shareRef}
        style={{ backgroundImage: `url(${image.localFile.childImageSharp.gatsbyImageData.images.fallback.src})` }}
        id="shareElement"
      >
        <h1>{header}</h1>
        <h2>{variantsTexts[randomNumber]}</h2>
      </ShareWrapper>

      <SocialWrapper>
        <SocialShare className={canShare ? 'canShare' : ''}>
          <ShareTitle>Share</ShareTitle>
          <SocialIcon
            onClick={() => openShareWindow(`https://www.facebook.com/sharer.php?u=${getRandomURL()}&quote=${header} ${variantsTexts[randomNumber]}`)}
          >
            <FacebookIcon />
          </SocialIcon>
          <SocialIcon
            onClick={() => openShareWindow(`http://twitter.com/share?url=${getRandomURL()}&text=${header} ${variantsTexts[randomNumber]}`)}
          >
            <TwitterIcon />
          </SocialIcon>
          <SocialIcon
            onClick={() => openShareWindow(
              `https://www.linkedin.com/sharing/share-offsite/?url=${getRandomURL()}`,
            )}
          >
            <LinkedinIcon />
          </SocialIcon>
          <ShareMobileTitle onClick={nativeShare}>•••</ShareMobileTitle>
        </SocialShare>

        {/* <SocialShareMobile onClick={nativeShare} className={canShare ? 'canShare' : ''}>
        </SocialShareMobile> */}

        <CopyLink onClick={setCopy}>
          {copied ? (
            <>
              <CheckIcon />
              Copied
            </>
          ) : (
            <>
              <LinkIcon />
              Copy link
            </>
          )}
        </CopyLink>
      </SocialWrapper>
    </Wrapper>
  );
};

export default SocialCard;
