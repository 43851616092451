import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Parallax } from 'react-scroll-parallax';

import { IMediaVideo } from 'interfaces/media';
import { IWidget } from 'interfaces/widgets';

import {
  Container, Wrapper, Title, Text, Video,
} from './Knife.styled';

const Knife: FC<IWidget<IMediaVideo, null>> = ({
  title, header, media,
}) => {
  const { isMobile } = useSelector((state: BlockState) => state);

  return (
    <Container>
      <Parallax y={[-15, 5]} disabled={isMobile}>
        <Wrapper>
          <Title>{title}</Title>
          <Text>{header}</Text>
        </Wrapper>
      </Parallax>

      <Video media={media[0]} playOnVisible loop />
    </Container>
  );
};

export default Knife;
