import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const ButtonStyles = css`
  background: rgba(255, 255, 255, .16);
  color: var(--white);
  font-weight: 500;
  padding: 0 calc(var(--unit) * 3.5);
  backdrop-filter: blur(calc(var(--unit) * 5));
  border-radius: var(--unit);

  height: calc(var(--unit) * 6);
  display: grid;
  align-content: center;
  justify-content: center;

  cursor: pointer;
`;

export const NextStyles = css`
  font-weight: 600;
  white-space: nowrap;
  display: flex;
  align-items: center;
  cursor: pointer;

  ::after {
    content: '↓';
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    margin-left: 1rem;
  }

  :hover {
    color: var(--gray);

    ::after {
      color: var(--gray);
    }
  }
`;

export const ModernButton = styled.button`
  ${ButtonStyles}
`;

export const ModernButtonLink = styled.a`
  ${ButtonStyles}
`;
