import React, { FC } from 'react';
import {
  FacebookIcon, InstagramIcon, LinkedinIcon, TwitterIcon,
} from 'UI/Icons';

import {
  Wrapper, Title, SocialLinksWrapper, SocialLinks, SocialLink, IconWrapper,
  LogosWrapper, Logo, SiteLink, WayRayLogo,
  LegalInfo, LegalLinksWrapper, LegalLink,
} from './Bottom.styled';

interface IBottom {
  legal: string;
}

const Bottom: FC<IBottom> = ({ legal }) => (
  <Wrapper>
    <LogosWrapper>
      <WayRayLogo />
      <Logo />
      <SiteLink href="https://wayray.com">wayray.com</SiteLink>
    </LogosWrapper>

    <SocialLinksWrapper>
      <Title>Follow WayRay on social media</Title>
      <SocialLinks>
        <SocialLink href="https://www.facebook.com/MyWayRay/">
          <IconWrapper>
            <FacebookIcon />
          </IconWrapper>
          Facebook
        </SocialLink>

        <SocialLink href="https://www.instagram.com/wayraygram/">
          <IconWrapper>
            <InstagramIcon />
          </IconWrapper>
          Instagram
        </SocialLink>

        <SocialLink href="https://twitter.com/WayRay/">
          <IconWrapper>
            <TwitterIcon />
          </IconWrapper>
          Twitter
        </SocialLink>

        <SocialLink href="https://www.linkedin.com/company/wayray/">
          <IconWrapper>
            <LinkedinIcon />
          </IconWrapper>
          Linkedin
        </SocialLink>
      </SocialLinks>
    </SocialLinksWrapper>

    <LegalInfo>{legal}</LegalInfo>

    <LegalLinksWrapper>
      <LegalLink href="https://wayray.com/policy/">Privacy Policy</LegalLink>
      <LegalLink href="https://wayray.com/terms/">Terms of Use</LegalLink>
      <LegalLink href="https://wayray.com/quality-policy/">Quality Policy</LegalLink>
    </LegalLinksWrapper>
  </Wrapper>
);

export default Bottom;
