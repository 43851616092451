import React, { FC } from 'react';

import { IMediaImage } from 'interfaces/media';
import { IWidget } from 'interfaces/widgets';

import {
  Wrapper, Description, SonsorsWrapper, Background, Slide,
} from './Sensors.styled';

const Sensors: FC<IWidget<IMediaImage, IMediaImage>> = ({
  header, slide, media,
}) => (
  <>
    <Wrapper>
      <Description>{header}</Description>
    </Wrapper>
    <SonsorsWrapper>
      {slide.map((item) => (
        <Slide key={item.id}>{item.header}</Slide>
      ))}
      <Background {...media?.[0]} />
    </SonsorsWrapper>
  </>
);

export default Sensors;
