import React, {
  FC, useRef, MutableRefObject, createRef,
} from 'react';

import { IMediaImage, IMediaSvg } from 'interfaces/media';
import { IWidget } from 'interfaces/widgets';

import Slide from './Slide/Slide';
import { Wrapper } from './Features.styled';

const Features: FC<IWidget<IMediaSvg, IMediaImage>> = ({ slide }) => {
  const wrapperRef = useRef<HTMLDivElement>();

  const slideRefs = useRef<MutableRefObject<HTMLDivElement>[]>(
    slide.map(() => createRef<HTMLDivElement>()),
  );

  const count = slide.length || 0;

  return (
    <Wrapper count={count + 1} ref={wrapperRef}>
      {slide.map((item, index) => (
        <Slide
          {...item}
          index={index}
          key={item.id}
          ref={slideRefs.current[index]}
        />
      ))}
    </Wrapper>
  );
};

export default Features;
