/* eslint-disable max-len */
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { StaticQuery, graphql } from 'gatsby';
import { ParallaxProvider } from 'react-scroll-parallax';

import { IWidget } from 'interfaces/widgets';
import { IPage, IQueryPage } from 'interfaces/page';
import { IMediaImage, IMediaVideo } from 'interfaces/media';

import Head from 'components/Layout/Head/Head';

import { Intersection } from 'UI';
import WelcomeBlock from 'components/blocks/Holograktor/WelcomeBlock/WelcomeBlock';
import Features from 'components/blocks/Holograktor/Features/Features';
import NextBlock from 'components/blocks/Holograktor/NextBlock/NextBlock';
import Passenger from 'components/blocks/Holograktor/Passenger/Passenger';
import HolograktorMenu from 'components/blocks/Holograktor/HolograktorMenu/HolograktorMenu';
import Metaverse from 'components/blocks/Holograktor/Metaverse/Metaverse';
import Gaming from 'components/blocks/Holograktor/Gaming/Gaming';
import ARGlazing from 'components/blocks/Holograktor/ARGlazing/ARGlazing';
import VRRC from 'components/blocks/Holograktor/VRRC/VRRC';
import Sensors from 'components/blocks/Holograktor/Sensors/Sensors';
import Disinfection from 'components/blocks/Holograktor/Disinfection/Disinfection';
import Knife from 'components/blocks/Holograktor/Knife/Knife';
import Interior from 'components/blocks/Holograktor/Interior/Interior';
import Footer from 'components/blocks/Holograktor/Footer/Footer';
import CookieModal from 'components/blocks/CookieModal/CookieModal';
import throttle from 'utils/throttle';
import { setWindowSize } from 'store';

type WidgetType = IWidget<unknown, unknown>;

interface IHolokractorPage extends IPage {
  widgets: WidgetType[];
}

interface IHolokractorQuery extends IQueryPage {
  page: IHolokractorPage;
}

const HolokractorPage: FC<IHolokractorPage> = ({ widgets }) => {
  const dispatch = useDispatch();

  const setBlock = ({ visibility }: ISetBlock) => {
    if (visibility) {
      // dispatch(setActiveBlock(`#${title}`));
    } else {
      // dispatch(setPreviousBlock(`#${title}`));
    }
  };

  const menu = widgets?.find(({ widget_name }) => widget_name === 'menu');
  const footer = widgets?.find(({ widget_name }) => widget_name === 'footer');

  const setMobile = () => {
    dispatch(setWindowSize(window.innerWidth));
  };

  const handleResize = throttle(setMobile, 200);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    setMobile();
    setTimeout(() => {
      setMobile();
    }, 300);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <>
      {menu && (
        <HolograktorMenu />
      )}
      {widgets?.map((widget) => {
        switch (widget.widget_name) {
          case 'welcome':
            return (
              <Intersection key={widget?.id} id={widget.title} way={setBlock}>
                <WelcomeBlock {...widget as IWidget<IMediaImage, null>} />
              </Intersection>
            );
          case 'features':
            return (
              <Intersection key={widget?.id} id={widget.title} way={setBlock}>
                <Features {...widget as IWidget<null, IMediaImage>} />
              </Intersection>
            );
          case 'passenger':
            return (
              <Intersection key={widget?.id} id={widget.title} way={setBlock}>
                <Passenger {...widget as IWidget<IMediaImage, IMediaImage | null>} />
              </Intersection>
            );
          case 'metaverse':
            return (
              <Intersection key={widget?.id} id={widget.title} way={setBlock}>
                <Metaverse {...widget as IWidget<IMediaImage, IMediaImage>} />
              </Intersection>
            );
          case 'gaming':
            return (
              <Intersection key={widget?.id} id={widget.title} way={setBlock}>
                <Gaming {...widget as IWidget<null, IMediaImage>} />
              </Intersection>
            );
          case 'arglazing':
            return (
              <Intersection key={widget?.id} id={widget.title} way={setBlock}>
                <ARGlazing {...widget as IWidget<IMediaImage, null>} />
              </Intersection>
            );
          case 'vrrc':
            return (
              <Intersection key={widget?.id} id={widget.title} way={setBlock}>
                <VRRC {...widget as IWidget<null, IMediaImage>} />
              </Intersection>
            );
          case 'sensors':
            return (
              <Intersection key={widget?.id} id={widget.title} way={setBlock}>
                <Sensors {...widget as IWidget<IMediaImage, IMediaImage>} />
              </Intersection>
            );
          case 'disinfection':
            return (
              <Intersection key={widget?.id} id={widget.title} way={setBlock}>
                <Disinfection {...widget as IWidget<null, IMediaImage>} />
              </Intersection>
            );
          case 'knife':
            return (
              <Intersection key={widget?.id} id={widget.title} way={setBlock}>
                <Knife {...widget as IWidget<IMediaVideo, null>} />
              </Intersection>
            );
          case 'interior':
            return (
              <Intersection key={widget?.id} id={widget.title} way={setBlock}>
                <Interior {...widget as IWidget<null, IMediaImage>} />
              </Intersection>
            );
          case 'next':
            return (
              <NextBlock key={widget?.id} {...widget as IWidget<null, null>} />
            );
          case 'next black':
            return (
              <NextBlock color="black" key={widget?.id} {...widget as IWidget<null, null>} />
            );
          case 'next bottom':
            return (
              <NextBlock position="bottom" key={widget?.id} {...widget as IWidget<null, null>} />
            );
          case 'next black bottom':
            return (
              <NextBlock position="bottom" color="black" key={widget?.id} {...widget as IWidget<null, null>} />
            );
          default:
            return null;
        }
      })}

      <Footer {...footer as IWidget<IMediaImage, null>} />
    </>
  );
};

const query = graphql`
  query {
    page: strapiHolograktorPage {
      meta {
        ...StrapiMetaFragment
      }
      widgets {
        ...StrapiComponentWidgetFragment
      }
    }
  }
`;

const HolograktorQuery: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IHolokractorQuery) => {
      const { meta, widgets } = data.page;

      let preview = meta.preview || '';
      let description = meta.description || '';

      if (typeof window !== 'undefined') {
        const footer = widgets?.find((item) => item.widget_name === 'footer');
        const { text, header } = footer;
        const variants = text?.[0]?.text.split(/\r?\n/);
        const hash = Number(window.location.hash?.replace('#', ''));

        if (variants[hash]) {
          preview = `/og-image/${hash}.png`;
          description = `${header} ${variants[hash]}`;
        }
      }

      return (
        <>
          <Head
            title={meta.title}
            description={description}
            preview={preview}
          />
          <ParallaxProvider>
            <HolokractorPage {...data.page} />
          </ParallaxProvider>
          <CookieModal />
        </>
      );
    }}
  />
);

export default HolograktorQuery;
