import styled from '@emotion/styled';
import { mobWidth } from 'constants/sizes';

import {
  GatsbyVideo,
  ModernLayout,
} from 'UI';

export const Container = styled.div`
  display: grid;

  grid-auto-flow: row;
`;

export const Wrapper = styled(ModernLayout)`
  padding-top: 13em;
  padding-bottom: calc(var(--unit) * 10);
  row-gap: calc(var(--unit) * 2);

  @media (max-width: ${mobWidth}) {
    align-content: space-between;
    padding-top: calc(var(--unit) * 10);
    padding-bottom: calc(var(--unit) * 14);
  }
`;

export const Title = styled.h2`
  grid-column: 1 / span 4;
`;

export const Text = styled.p`
  grid-column: 1 / span 8;
  font-size: 1.4em;

  @media (max-width: ${mobWidth}) {
    grid-column: 1 / -1;
    font-size: 1em;
  }
`;

export const Video = styled(GatsbyVideo)`
  :after {
    content: 'AirKnife system testing';
    position: absolute;
    bottom: calc(var(--unit) * 8);
    left: calc(var(--unit) * 8);
    color: var(--white);
    font-size: 1.4rem;
  }

  @media (max-width: ${mobWidth}) {
    > video {
      aspect-ratio: 1 / 1;
      object-fit: cover;
      height: 100vw;
      bottom: 20px;
      left: 20px;
    }

    :after {
      content: 'AirKnife system testing';
      position: absolute;
      top: calc(-1em - 20px);
      left: 20px;
      color: var(--black);
      font-size: 1rem;
    }
  }
`;
