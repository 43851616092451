import React, { FC } from 'react';

import { IComponentWidgetText } from 'interfaces/widgets';

import {
  Wrapper, Title, Header, PressKitWrapper, SpanTitle, ContactWrapper,
  ButtonLink, PressKitLink,
} from './FollowCard.styled';

interface ISocialCard {
  header: string;
  title: string;
  text: IComponentWidgetText[];
}

const FollowCard: FC<ISocialCard> = ({
  title, header, text,
}) => {
  const pressTitle = text?.find((item) => item.title === 'presskit');
  const contactTitle = text?.find((item) => item.title === 'contact');

  return (
    <Wrapper>
      <Title>{title}</Title>
      <Header>{header}</Header>
      <PressKitWrapper>
        <SpanTitle>{pressTitle.text}</SpanTitle>
        <PressKitLink href="https://wayray.com/press-releases/holograktor">Press Kit</PressKitLink>
      </PressKitWrapper>
      <ContactWrapper>
        <SpanTitle>{contactTitle.text}</SpanTitle>
        <ButtonLink href="mailto:business@holograktor.com">Business Inquires</ButtonLink>
        <ButtonLink href="mailto:pr@holograktor.com">PR & Marketing</ButtonLink>
      </ContactWrapper>
    </Wrapper>
  );
};

export default FollowCard;
