import styled from '@emotion/styled';
import { mobWidth, tabletWidth } from 'constants/sizes';

import {
  GatsbyPicture,
  ModernLayout,
} from 'UI';

const Picture = styled(GatsbyPicture)`
  @media (max-width: ${mobWidth}) {
    img {
      aspect-ratio: 1 / 1;
      object-fit: cover;
      height: 100vw;
    }
  }
`;

const FullWidthPicture = styled(Picture)`
  @media (max-width: ${mobWidth}) {
    margin-left: -20px;
    margin-right: -20px;
  }
`;

export const Container = styled.div`
  display: flex;

  flex-direction: column;
  width: 100%;
`;

export const Wrapper = styled(ModernLayout)`
  background: var(--white);
  padding-top: 10em;
  padding-bottom: calc(var(--unit) * 8);

  @media (max-width: ${mobWidth}) {
    row-gap: var(--unit);
    padding-top: calc(var(--unit) * 12);
    padding-bottom: 1.5rem;
  }
`;

export const SecondWrapper = styled(ModernLayout)`
  background: var(--white);
  padding-top: 6em;
  padding-bottom: calc(var(--unit) * 5);

  @media (max-width: ${mobWidth}) {
    row-gap: 20px;
    padding-top: calc(var(--unit) * 12);
    padding-bottom: 0;
  }
`;

export const Title = styled.h2`
  grid-column: 1 / span 8;
  grid-row: 1;
  margin-bottom: 2em;

  @media (max-width: ${mobWidth}) {
    grid-column: 1 / -1;
    margin-bottom: 1.7em;
  }
`;

const Text = styled.div`
  font-size: 1.4em;

  @media (max-width: ${mobWidth}) {
    font-size: 1em;
  }
`;

export const SlideOneText = styled(Text)`
  grid-column: 11 / -1;
  grid-row: 2;

  @media (max-width: ${mobWidth}) {
    grid-column: 1 / -1;
  }
`;

export const SlideOneImage = styled(Picture)``;

export const SlideTwoText = styled(Text)`
  grid-column: 1 / span 8;
  margin-bottom: calc(var(--unit) * 8);

  @media (max-width: ${mobWidth}) {
    grid-column: 1 / -1;
    margin-bottom: 0;
  }
`;

export const SlideTwoImage = styled(FullWidthPicture)`
  grid-column: 1 / span 14;
  margin-bottom: calc(var(--unit) * 20);

  @media (max-width: ${tabletWidth}) {
    margin-bottom: calc(var(--unit) * 10);
  }

  @media (max-width: ${mobWidth}) {
    grid-column: 1 / -1;
    margin-bottom: 4em;
  }
`;

export const SlideThreeText = styled(Text)`
  align-self: stretch;
  grid-column: 1 / span 6;
  margin-bottom: calc(var(--unit) * 20);

  div {
    height: 100%;
  }

  @media (max-width: ${tabletWidth}) {
    margin-bottom: calc(var(--unit) * 10);
  }

  @media (max-width: ${mobWidth}) {
    grid-column: 1 / -1;
    margin-bottom: 0;
  }
`;

export const SlideThree = styled.div`
  grid-column: 7 / -1;
  margin-bottom: calc(var(--unit) * 20);

  @media (max-width: ${tabletWidth}) {
    margin-bottom: calc(var(--unit) * 10);
  }

  @media (max-width: ${mobWidth}) {
    grid-column: 1 / -1;
    margin-bottom: 0;
    margin: -20px;
  }
`;

export const SlideThreeImage = styled(FullWidthPicture)``;

export const SlideFourImage = styled(FullWidthPicture)``;

export const SlideFourWrapper = styled.div`
  grid-column: 1 / span 14;
  margin-bottom: -5%;

  @media (max-width: ${tabletWidth}) {
    margin-bottom: 4em;
  }

  @media (max-width: ${mobWidth}) {
    grid-column: 1 / -1;
    margin-bottom: 0;
    padding-bottom: 6em;
    margin: -20px;
  }
`;
