/* eslint-disable max-lines */
import styled from '@emotion/styled';
import { mobWidth, tabletWidth } from 'constants/sizes';

import { ModernLayout } from 'UI';

export const Wrapper = styled(ModernLayout)`
  grid-column: 1 / -1;

  background: rgba(255, 255, 255, 0.08);
  border-radius: var(--unit);
  padding-top: calc(var(--unit) * 8);
  padding-bottom: calc(var(--unit) * 8);
  backdrop-filter: blur(calc(var(--unit) * 12));

  @media (max-width: ${mobWidth}) {
    row-gap: calc(var(--unit) * 2);
  }
`;

export const Header = styled.h2`
  grid-column: 1 / span 12;
  color: var(--mambo-green);

  @media (max-width: ${tabletWidth}) {
    grid-column: 1 / -1;
  }

  @media (max-width: ${mobWidth}) {
    grid-column: 1 / -1;
  }
`;

export const RandomText = styled.h2`
  grid-column: 1 / span 12;
  margin-bottom: 0.79em;
  min-height: 2em;

  @media (max-width: ${tabletWidth}) {
    grid-column: 1 / -1;
  }

  @media (max-width: ${mobWidth}) {
    grid-column: 1 / -1;
  }
`;

export const Icon = styled.span`
  background: var(--white);
  display: inline-block;
  padding: var(--unit);
  border-radius: calc(var(--unit) / 2);
  transition: 0.2s ease-in-out;

  svg {
    width: 0.6em;
    height: 0.6em;
    fill: var(--black);
    transition: 0.2s ease-in-out;
  }
`;

export const RandomTitle = styled.span`
  font-size: 1.4em;
  color: var(--white);
  grid-column: 1 / span 8;
  grid-row: 3;
  cursor: pointer;
  justify-self: start;
  display: flex;
  align-items: center;
  transition: 0.2s ease-in-out;
  margin-right: auto;
  gap: 0.5em;
  font-weight: 600;

  :hover {
    color: var(--purple);

    ${Icon} {
      background: var(--purple);

      svg {
        fill: var(--white);
      }
    }
  }

  @media (max-width: ${tabletWidth}) {
    grid-column: 1 / 8;
  }

  @media (max-width: ${mobWidth}) {
    font-size: 1em;
    grid-column: 1 / -2;
    margin-bottom: calc(var(--unit) * 4);
    margin-top: calc(var(--unit) * 6);
  }
`;

export const SocialWrapper = styled.div`
  grid-row: 3;
  grid-column: 12 / -1;
  display: grid;
  gap: var(--unit);
  align-items: center;
  grid-template-columns: 1fr 1fr;

  @media (max-width: ${tabletWidth}) {
    grid-column: 9 / -1;
  }

  @media (max-width: ${mobWidth}) {
    grid-row: 4;
    grid-column: 1 / -1;
    align-items: stretch;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const SocialShare = styled.div`
  background: var(--white);
  border-radius: var(--unit);
  font-weight: 600;
  color: var(--black);
  height: calc(var(--unit) * 6);
  padding: 0 1.56em;
  display: flex;
  align-items: center;
  gap: calc(var(--unit) * 2);

  @media (max-width: ${mobWidth}) {
    padding: 0 0 0 1em;
    gap: 1em;
    grid-column: 1 / -1;
  }
`;

export const ShareTitle = styled.span`
  @media (max-width: ${mobWidth}) {
    text-align: center;
    margin-right: auto;
  }
`;

export const ShareMobileTitle = styled.button`
  display: none;
  padding-left: 1em;
  padding-right: 1em;
  margin-left: 0.3em;
  border-left: 1px solid var(--mambo-green);
  color: var(--black);

  :hover {
    color: var(--black);
  }

  @media (max-width: ${mobWidth}) {
    display: block;
  }
`;

export const SocialShareMobile = styled.button`
  display: none;
  background: var(--white);
  border-radius: var(--unit);
  font-weight: 600;
  color: var(--black);
  height: calc(var(--unit) * 6);
  cursor: pointer;
  padding: 0;

  :hover {
    color: var(--black);
  }

  @media (max-width: ${mobWidth}) {
    display: block;
    grid-column: 3;
  }
`;

export const SocialIcon = styled.a`
  width: calc(var(--unit) * 4);
  height: calc(var(--unit) * 4);
  background: var(--black);
  border-radius: calc(var(--unit) / 2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    fill: var(--white);
    width: calc(var(--unit) * 2);
    height: calc(var(--unit) * 2);
  }

  :hover {
    background: var(--purple);
  }
`;

export const CopyLink = styled.button`
  width: 8.8em;
  background: rgba(255, 255, 255, 0.16);
  border-radius: var(--unit);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--unit);

  font-weight: bold;
  color: var(--white);
  cursor: pointer;
  height: calc(var(--unit) * 6);

  svg {
    width: 1em;
    height: 1em;
    fill: var(--white);
  }

  :hover {
    color: var(--purple);

    svg {
      fill: var(--purple);
    }
  }

  @media (max-width: ${mobWidth}) {
    width: auto;
    grid-row: 2;
    grid-column: 1 / -1;
  }
`;

export const ShareWrapper = styled.div`
  width: 100vw;
  height: 100vw;
  grid-column: 1 / -1;
  background-size: cover;
  background-position: center;
  padding: 5vw;
  position: absolute;
  display: none;
  left: 0;

  > h1 {
    font-size: 6vw;
    color: var(--mambo-green);
  }

  > h2 {
    font-size: 6vw;
    color: var(--white);
  }
`;
