import React, { FC, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Parallax } from 'react-scroll-parallax';

import { IMediaImage, IMediaVideo } from 'interfaces/media';
import { IWidget } from 'interfaces/widgets';

import {
  Wrapper, FirstSlide, BackgroundWrapper, Title, HeaderOne, Header,
  Description, SecondSlide, FirstSlideContainer, DescriptionOne,
  PhonesWrapper, PhoneImage, OnePhoneImage, MobileBackgroundWrapper, BackgroundVideo,
} from './Passenger.styled';

const Passenger: FC<IWidget<IMediaImage, IMediaImage | IMediaVideo>> = ({ slide, media }) => {
  const { isMobile } = useSelector((state: BlockState) => state);
  const wrapperRef = useRef<HTMLDivElement>();
  const firstSlide = useRef<HTMLDivElement>();

  const slideOne = slide?.[0];
  const slideTwo = slide?.[1];

  const phoneOne = media?.[0];
  const phoneTwo = media?.[1];

  return (
    <Wrapper ref={wrapperRef}>
      {slideOne && (
        <FirstSlideContainer>
          <Parallax y={[-15, 10]} disabled={isMobile}>
            <FirstSlide ref={firstSlide}>
              <Title>{slideOne.title}</Title>
              <HeaderOne>{slideOne.header}</HeaderOne>
              <DescriptionOne>{slideOne.text?.[0]?.text}</DescriptionOne>
            </FirstSlide>
          </Parallax>
        </FirstSlideContainer>
      )}

      <BackgroundWrapper>
        <BackgroundVideo playOnVisible media={slideOne.media as IMediaVideo} loop />
      </BackgroundWrapper>

      <MobileBackgroundWrapper>
        <BackgroundVideo playOnVisible media={slideOne.media as IMediaVideo} loop />
      </MobileBackgroundWrapper>

      {slideTwo && (
        <SecondSlide>
          <Title>{slideTwo.title}</Title>
          <Header>{slideTwo.header}</Header>
          <Description>
            {slideTwo.text?.[0]?.text}
            {/* <Next onClick={scrollIntoBlock}>Metaverse</Next> */}
          </Description>
        </SecondSlide>
      )}

      <PhonesWrapper>
        <Parallax y={[10, -10]} disabled={isMobile}>
          <OnePhoneImage {...phoneTwo} />
        </Parallax>
        <Parallax y={[30, -30]} disabled={isMobile}>
          <PhoneImage {...phoneOne} />
        </Parallax>
      </PhonesWrapper>
    </Wrapper>
  );
};

export default Passenger;
