import styled from '@emotion/styled';

import { ScrollableSlider } from 'UI';

interface IWrapper {
  count: number;
}

export const Wrapper = styled.div<IWrapper>`
  color: var(--white);
  width: 100%;
  /* line-height: 1.67; */
  /* height: ${({ count }) => count * 100}vh; */
  z-index: 1;
  /* clip-path: inset(0 0 0); */
`;

export const Slider = styled(ScrollableSlider)`
`;
