import styled from '@emotion/styled';
import { mobWidth } from 'constants/sizes';
import Markdown from 'markdown-to-jsx';

import {
  GatsbyPicture, GatsbySvg, ModernLayout,
} from 'UI';

export const Container = styled.div`
`;

export const Wrapper = styled(ModernLayout)`
  padding-top: 10em;
  padding-bottom: calc(var(--unit) * 4);
  row-gap: calc(var(--unit) * 2);

  @media (max-width: ${mobWidth}) {
    padding-top: calc(var(--unit) * 4);
    padding-bottom: calc(var(--unit) * 4);
    background: var(--black);
    color: var(--white);
    box-shadow: 0 0 0 4px var(--black);
  }
`;

export const WrapperText = styled(ModernLayout)`
  padding-top: 8em;
  padding-bottom: 8em;
  row-gap: calc(var(--unit) * 4);
  background: #000;
  color: var(--white);

  @media (max-width: ${mobWidth}) {
    padding-top: 4em;
    row-gap: calc(var(--unit) * 2);
    padding-bottom: 5em;
  }
`;

export const Title = styled.h2`
  grid-column: 1 / span 8;
  margin-bottom: 0;

  @media (max-width: ${mobWidth}) {
    grid-column: 1 / -1;
    margin-top: calc(var(--unit) * 8);
    margin-bottom: 0;
  }
`;

export const Header = styled.p`
  grid-column: 1 / span 8;
  font-size: 1.4em;

  @media (max-width: ${mobWidth}) {
    grid-column: 1 / -1;
    font-size: 1em;
  }
`;

export const Picture = styled(GatsbyPicture)`
  width: 100%;
  height: auto;

  @media (max-width: ${mobWidth}) {
    img {
      aspect-ratio: 1 / 1;
      object-fit: cover;
      height: 100vw;
    }
  }
`;

export const Logo = styled(GatsbySvg)`
  grid-column: 1 / span 5;
  height: auto;
  grid-row: 1;

  svg {
    path {
      fill: var(--white);
    }
  }

  @media (max-width: ${mobWidth}) {
    grid-column: 1 / -1;

    > svg {
      max-width: 90%;
    }
  }
`;

export const TextDescription = styled(Markdown)`
  grid-column: 1 / span 8;
  grid-row: 2;
  font-size: 1.4rem;
  line-height: 1.4;

  > a {
    color: var(--white);
    border-radius: 0.5em;
    background: var(--blue);
    border: 0.3em solid var(--blue);
    padding: 0 0.0em;
    margin: 0 -0.1em;
    white-space: nowrap;

    ::after {
      content: '↗';
      margin-left: 0.2em;
    }

    :hover {
      background: var(--purple);
      border-color: var(--purple);
    }
  }

  @media (max-width: ${mobWidth}) {
    grid-column: 1 / -1;
    font-size: 1em;
  }
`;

export const TextHeader = styled.h2`
  grid-column: 7 / -1;
  grid-row: 3;
  margin-top: 2em;

  @media (max-width: ${mobWidth}) {
    grid-column: 1 / -1;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
`;

export const TextFootnote = styled.p`
  grid-row: 3;
  grid-column: 1 / span 5;
  align-self: end;
  font-size: 1.4em;

  @media (max-width: ${mobWidth}) {
    grid-row: 4;
    font-size: 1em;
  }
`;
