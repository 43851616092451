import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Parallax } from 'react-scroll-parallax';

import { IWidget } from 'interfaces/widgets';

import {
  Wrapper, Header, Description,
} from './NextBlock.styled';

interface INextBlock extends IWidget<null, null> {
  color?: 'black' | 'white';
  position?: 'bottom' | 'right';
}

const NextBlock: FC<INextBlock> = ({
  header,
  text,
  color = '',
  position = '',
}) => {
  const { isMobile } = useSelector((state: BlockState) => state);
  const description = text?.find(({ title }) => title === 'description')?.text;

  return (
    <Parallax y={[-10, 20]} disabled={isMobile}>
      <Wrapper className={`${color} ${position}`}>
        <Header>{header}</Header>
        {description && (
          <Description>
            {description}
          </Description>
        )}
      </Wrapper>
    </Parallax>
  );
};

export default NextBlock;
