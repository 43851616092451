import styled from '@emotion/styled';
import { mobWidth } from 'constants/sizes';

import { ModernLayout } from 'UI';

export const Header = styled.h2`
  grid-column: 1 / span 8;
  grid-row: 1;

  @media (max-width: ${mobWidth}) {
    grid-column: 1 / -1;
  }
`;

export const Description = styled.p`
  grid-column: 11 / -1;
  font-size: 1.4rem;
  grid-row: 1;

  @media (max-width: ${mobWidth}) {
    grid-row: 2;
    grid-column: 1 / -1;
    font-size: 1rem;
  }
`;

export const Wrapper = styled(ModernLayout)`
  height: auto;
  background: var(--white);
  padding-top: calc(var(--unit) * 15);
  padding-bottom: calc(var(--unit) * 4);
  row-gap: calc(var(--unit) * 2);
  align-items: start;
  margin-bottom: 5%;

  &.black {
    background: var(--black);
    color: var(--white);
  }

  &.bottom {
    ${Description} {
      grid-column: 1 / span 8;
      grid-row: 2;
    }
  }

  @media (max-width: ${mobWidth}) {
    padding-top: calc(var(--unit) * 10);
    padding-bottom: calc(var(--unit) * 10);
    margin-bottom: 0;
  }
`;
