import React, { FC } from 'react';

interface IIcon {
  className?: string;
  width?: string;
}

const HolograktorLogo: FC<IIcon> = ({ className = '', ...props }) => (
  <svg
    width="230"
    height="20"
    viewBox="0 0 230 20"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path d="M210.921 3.80567H220.373L219.427 5.66802H210.03V5.68151L224.329 19.973H230L219.387 9.39271H222.222L226.921 0H208.112L200.564 15.1012H205.277L210.921 3.80567Z" />
    <path d="M94.4355 15.1014H99.1614L103.833 5.69518H103.847L104.778 3.83284H114.176L113.244 5.69518H103.847H103.833L118.159 20.0002H123.79L113.19 9.41988H116.026L120.738 0.0136719H101.983L94.9756 14.0218L94.4355 15.1014Z" />
    <path d="M7.49385 9.4189H16.9321L14.11 15.1004H18.8089L26.3433 0.0126953H21.6309L18.8089 5.65372H9.35719L12.2062 0.0126953H7.49385L0 15.0464H4.65834L7.49385 9.4189Z" />
    <path d="M39.6154 15.1016L47.1632 0.0273438H28.3408L20.793 15.1016H39.6154ZM31.1223 3.79253H40.574L36.8069 11.3364H27.3552L31.1223 3.79253Z" />
    <path d="M53.5768 15.1014L55.4402 11.3227H48.3784L54.0224 0.0136719H49.3101L41.7622 15.1014H53.5768Z" />
    <path d="M74.2365 15.1016L81.7843 0.0273438H62.9619L55.4141 15.1016H74.2365ZM65.8109 3.79253H75.2221L71.455 11.3364H62.0438L65.8109 3.79253Z" />
    <path d="M147.892 5.66797L147.905 5.68146L147.919 5.66797H147.892Z" />
    <path d="M147.528 5.66819L150.363 0.0136719H145.637L138.103 15.1014H142.815L147.528 5.68169V5.66819Z" />
    <path d="M156.547 9.39271H159.747L164.459 0H159.747L156.912 5.66802H147.527V5.68151L161.84 20H167.349L156.547 9.39271Z" />
    <path d="M165.986 15.1014H170.685L176.342 3.79235H183.377L185.268 0.0136719H166.445L164.568 3.79235H171.644L165.986 15.1014Z" />
    <path d="M198.581 15.1016L206.129 0.0273438H187.306L179.758 15.1016H198.581ZM190.155 3.79253H199.566L195.799 11.3364H186.388L190.155 3.79253Z" />
    <path d="M97.7169 3.79235L99.6072 0.0136719H83.7554L76.3696 14.764L76.2075 15.1014H92.0594H94.2603L93.5987 12.011L92.4239 6.46441H88.8863L89.764 11.8895H82.5537L86.5909 3.79235H97.7169Z" />
    <path d="M210.368 5.66797L210.38 5.68079V5.66797H210.368Z" />
    <path d="M124.831 0.0136719L118.498 12.6722L121.671 15.8436L124.817 9.46037H134.228L131.393 15.0879H136.105L143.653 0.0136719H124.831ZM126.748 5.6547L127.693 3.79235H137.104L136.159 5.6547H126.748Z" />
  </svg>
);

export default HolograktorLogo;
