import styled from '@emotion/styled';

import { GatsbyPicture, ModernLayout } from 'UI';
import { mobWidth } from 'constants/sizes';

export const Background = styled(GatsbyPicture)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  will-change: transform;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const BackgroundMobile = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  will-change: transform;
  display: none;
  object-fit: cover;

  @media (max-width: ${mobWidth}) {
    display: block;
  }
`;

export const Title = styled.h2`
  grid-column: 1 / span 7;

  @media (max-width: ${mobWidth}) {
    grid-column: 1 / -1;
  }
`;

export const Description = styled.p`
  grid-column: 11 / -1;
  font-size: 1.4rem;

  @media (max-width: ${mobWidth}) {
    font-size: 1rem;
    grid-column: 1 / -1;
  }
`;

export const Wrapper = styled(ModernLayout)`
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  position: relative;
  color: var(--white);
  padding-top: calc(var(--unit) * 4);
  padding-bottom: calc(var(--unit) * 4);
  align-items: end;

  @media (max-width: ${mobWidth}) {
    align-items: start;
    align-content: start;
    row-gap: 1em;
  }
`;
