import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Parallax } from 'react-scroll-parallax';

import { IWidget } from 'interfaces/widgets';
import { IMediaImage } from 'interfaces/media';

import {
  Wrapper, Title, Header, Description, SlideOne, SlideTwo, SlideTwoText,
  SlideOneWrapper, DescriptionWrapper, SlideTwoTextWrapper,
} from './Gaming.styled';
import Shifter from './Shifter/Shifter';

const Gaming: FC<IWidget<null, IMediaImage>> = ({
  title, text, header, slide,
}) => {
  const { isMobile } = useSelector((state: BlockState) => state);

  const one = slide.find((item) => item.title === 'one');
  const two = slide.find((item) => item.title === 'two');
  const four = slide.find((item) => item.title === 'four');
  const five = slide.find((item) => item.title === 'five');

  return (
    <>
      <Wrapper>
        <Title>{title}</Title>
        <Header>{header}</Header>
        <DescriptionWrapper>
          <Parallax y={[10, -15]} disabled={isMobile}>
            <Description>{text?.[0]?.text}</Description>
          </Parallax>
        </DescriptionWrapper>
        {one?.media && (
          <SlideOneWrapper>
            <Parallax y={[20, -20]} disabled={isMobile}>
              <SlideOne {...one.media} />
            </Parallax>
          </SlideOneWrapper>
        )}
        {(two?.media && two.header) && (
          <>
            <SlideTwo {...two.media} />
            <SlideTwoTextWrapper>
              <Parallax y={[50, 0]} disabled={isMobile}>
                <SlideTwoText>{two.header}</SlideTwoText>
              </Parallax>
            </SlideTwoTextWrapper>
          </>
        )}
      </Wrapper>
      <Shifter slides={[four, five]} />
    </>
  );
};

export default Gaming;
