import styled from '@emotion/styled';

import { mobWidth } from 'constants/sizes';
import { GatsbyPicture, ModernLayout } from 'UI';

export const Wrapper = styled(ModernLayout)`
  padding-top: 6em;
  padding-bottom: calc(var(--unit) * 8);
  row-gap: calc(var(--unit) * 2);
  grid-template-columns: repeat(8, minmax(0, 1fr));
  background: var(--black);
  color: var(--white);
  grid-template-areas:
    't t t t t t t t'
    'h h h h h . . .'
    '. . . . . d d d'
    'm m m m m m m .'
    'q q q s s s s s';

  @media (max-width: ${mobWidth}) {
    box-shadow: 0 0 0 4px var(--black);
    row-gap: 20px;
    padding-bottom: calc(var(--unit) * 4);
    grid-template-areas:
      't t t t'
      'h h h h'
      'd d d d'
      'm m m m'
      'q q q q'
      's s s s';
  }
`;

export const Title = styled.p`
  grid-area: t;
  font-weight: 600;
`;

export const Header = styled.h2`
  grid-area: h;
  margin-bottom: calc(var(--unit) * 18);

  @media (max-width: ${mobWidth}) {
    margin-bottom: calc(var(--unit) * 8);
  }
`;

export const DescriptionWrapper = styled.div`
  grid-area: d;
  margin-bottom: calc(var(--unit) * 10);
  font-size: 1.4em;

  @media (max-width: ${mobWidth}) {
    font-size: 1em;
    margin-bottom: 0;
  }
`;

export const Description = styled.p`
`;

export const SlideOneWrapper = styled.div`
  grid-area: m;
  margin-bottom: calc(var(--unit) * 8);

  @media (max-width: ${mobWidth}) {
    margin-left: calc(var(--unit) * -3);
    margin-right: calc(var(--unit) * -3);
  }
`;

export const SlideOne = styled(GatsbyPicture)`
  @media (max-width: ${mobWidth}) {
    img {
      aspect-ratio: 1 / 1;
      object-fit: cover;
      height: 100vw;
    }
  }
`;

export const SlideTwo = styled(GatsbyPicture)`
  grid-area: s;
  margin-bottom: calc(var(--unit) * 8);

  @media (max-width: ${mobWidth}) {
    margin-left: calc(var(--unit) * -3);
    margin-right: calc(var(--unit) * -3);
    margin-bottom: 0;

    img {
      aspect-ratio: 1 / 1;
      object-fit: cover;
      height: 100vw;
    }
  }
`;

export const SlideTwoTextWrapper = styled.div`
  grid-area: q;
  font-size: 1.4em;

  @media (max-width: ${mobWidth}) {
    font-size: 1em;
  }
`;

export const SlideTwoText = styled.p`
`;
