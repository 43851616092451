import React, { FC, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Parallax } from 'react-scroll-parallax';

import { IMediaImage } from 'interfaces/media';
import { IWidget } from 'interfaces/widgets';

import {
  Title, Wrapper, Text, Layout, Container, MobileText, StickyContainer,
  BackgroundImage, ImagesWrapper, Image, TextWrapper, BackgroundMobile, AnimatedBack,
  DescriptionWrapper,
} from './WelcomeBlock.styled';

const WelcomeBlock: FC<IWidget<IMediaImage, IMediaImage>> = ({
  header, text, media, slide,
}) => {
  const wrapperRef = useRef<HTMLDivElement>();
  const { isMobile } = useSelector((state: BlockState) => state);

  const imageDescktop = media?.[0];
  const imageMobile = media?.[1];

  return (
    <Container>
      {/* <StickyContainer>
        <Wrapper ref={wrapperRef}>
          {isMobile ? (
            <BackgroundMobile {...imageMobile} />
          ) : (
            <AnimatedBack />
          )}
          <Layout>
            <BackgroundImage {...imageDescktop} />
            <BackgroundMobile {...imageMobile} />
            <Title>{header}</Title>
            {text?.[0]?.text && (
              <MobileText>
                {text?.[0]?.text}
              </MobileText>
            )}
          </Layout>
        </Wrapper>
      </StickyContainer> */}

      <Wrapper ref={wrapperRef}>
        <Layout>
          <BackgroundImage {...imageDescktop} />
          <BackgroundMobile {...imageMobile} />
          <Title>{header}</Title>
          {text?.[0]?.text && (
            <MobileText>
              {text?.[0]?.text}
            </MobileText>
          )}
        </Layout>
      </Wrapper>

      <TextWrapper>
        {text?.[0]?.text && (
          <DescriptionWrapper>
            <Parallax y={[-30, 10]} disabled={isMobile}>
              <Text>
                {text?.[0]?.text}
              </Text>
            </Parallax>
          </DescriptionWrapper>
        )}
      </TextWrapper>
      <ImagesWrapper>
        {slide.map((item, index) => (
          <Image key={item.id} {...item.media} className={`index_${index}`} />
        ))}
      </ImagesWrapper>
    </Container>
  );
};

export default WelcomeBlock;
