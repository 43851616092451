import styled from '@emotion/styled';

export const Wrapper = styled.div`
  position: relative;
`;

export const Canvas = styled.canvas`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
