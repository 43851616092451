import styled from '@emotion/styled';
import { mobWidth } from 'constants/sizes';

import { GatsbyPicture, ModernLayout } from 'UI';

export const Container = styled(ModernLayout)`
  background: var(--black);
  padding-top: 53vw;
  display: grid;
  grid-auto-flow: row;
  position: relative;
  row-gap: calc(var(--unit) * 2);

  color: var(--white);

  > * {
    z-index: 1;
  }

  @media (max-width: ${mobWidth}) {
    padding-top: 40vh;
  }
`;

export const Background = styled(GatsbyPicture)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;

  :after {
    content: '';
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 15%;
    width: 100%;
  }

  @media (max-width: ${mobWidth}) {
    > img {
      aspect-ratio: 1 / 1;
      object-fit: cover;
      height: 100vw;
    }
  }
`;
